import React from "react";
import { Box, List, Typography } from "@mui/material";
import CatalogSearchBreadcrumbs from "./SearchBreadcrumbs";

interface CatalogSearchItemList {
  item: any;
  searchText: string;
}

const CatalogSearchItemList = ({ item, searchText }: CatalogSearchItemList) => (
  <Box>
    <Typography variant="h4" color="primary">
      {item.name}
    </Typography>
    <List>
      {item.breadcrumbs.map((breadcrumb: any, key: number) => (
        <CatalogSearchBreadcrumbs
          key={`CatalogSearchItemList-breadcumbs-${key}`}
          searchText={searchText}
          breadcrumbs={breadcrumb}
        />
      ))}
    </List>
  </Box>
);

export default CatalogSearchItemList;
