import React from "react";
import {
  Box,
  IconButton,
  Modal,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

const BoxStyled = styled(Box)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  padding: "10px 40px 0px 40px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const ModalBase = ({
  open,
  onClose,
  sx,
  color = "primary",
  title,
  children,
  ...otherProps
}: any) => {
  return (
    <>
      <Modal open={open} onClose={onClose} aria-labelledby={title}>
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "95%",
            overflow: "hidden",
            borderRadius: 3,
            border: "none",
            ...sx,
          }}
          variant="outlined"
          {...otherProps}
        >
          <BoxStyled>
            <Typography id={title} variant="h3" fontSize={{ xs: 30, md: 40 }}>
              {title}
            </Typography>
            <IconButton onClick={onClose}>
              <ClearRoundedIcon
                sx={{ color: "common.white" }}
                fontSize="large"
              />
            </IconButton>
          </BoxStyled>
          <Box marginTop={2} padding={{ xs: 2, md: 4 }}>
            {children}
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default ModalBase;
