import React from 'react';
import ServiceFilterButton from './ServiceFilterButton';
import { Stack } from '@mui/material';

const ServiceFilterList = ({ services }) => (
  <Stack spacing={{ xs: 1 }} direction="row" useFlexGap flexWrap="wrap">
    {services?.map(service => (
      <ServiceFilterButton key={`Service-${service.id}`} sx={{ borderRadius: 3, py: 1.5 }} value={service.slug}>
        {service.name}
      </ServiceFilterButton>
    ))}
  </Stack>
);

export default ServiceFilterList;