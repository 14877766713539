import React from 'react';
import { Link as MuiLink, Breadcrumbs, Typography, alpha, useTheme } from '@mui/material';
import { NavigateNext as NavigateNextIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { CatalogService } from '../../services/CatalogService';

interface CatalogSearchBreadcrumbsProps {
  searchText: string;
  breadcrumbs: any;
}

export const CatalogSearchBreadcrumbs = ({ searchText, breadcrumbs }: CatalogSearchBreadcrumbsProps) => {
  let url = '';
  const theme = useTheme();

  return (
    <div>
      <Breadcrumbs separator={<NavigateNextIcon />}>
        {breadcrumbs.map((breadcrumb: any, key: number) => {
          const innerHTML = CatalogService.highlight(breadcrumb.name, searchText);

          if (breadcrumb.path) {
            url += `${breadcrumb.path}`;


            return (
              <MuiLink
                key={`SearchBreadcrumbs-ItemList-${key}`}
                color="inherit"
                underline="none"
                component={Link}
                to={`/catalog${url}`}
                dangerouslySetInnerHTML={{ __html: innerHTML }}
              />
            )
          }

          return (
            <Typography
              key={`SearchBreadcrumbs-ItemList-${key}`}
              sx={{ color: alpha(theme.palette.text.secondary, 0.3)}}
              dangerouslySetInnerHTML={{__html: innerHTML }}
            />
          )
        })}
      </Breadcrumbs>
    </div>
  )
}

export default CatalogSearchBreadcrumbs;
