import React from 'react';
import { CatalogueLayout } from '../layouts';
import { SegmentFilterSelect } from '../components/catalog';
import { Grid2, IconButton, ListItem, Stack, Typography, styled, Button } from '@mui/material'
import { CardTitle } from '../components/card'
import SliderCustom from '../components/SliderCustom'
import {
  ArrowForwardIosRounded as ArrowForwardIosRoundedIcon
} from '@mui/icons-material';
import { useQuery } from 'react-query';
import axios from '../api/axios';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "white",
  '&: hover': {
    backgroundColor: theme.palette.secondary.main,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 14
  }
}))

const Page = () => {
  const { data } = useQuery('home', () => axios.get('/content/home').then(res => res.data?.data));

  const replaceWithBr = () => data?.welcome?.replace(/\n/g, "<br />")
  

  return (
    <CatalogueLayout 
      title="Accueil"
      action={
        <SegmentFilterSelect style={{ position: { md: 'absolute' } }} sx={{ borderRadius: 3, textTransform: 'initial' }} />
      }
    >
      <Grid2 container spacing={4}>
        <Grid2 size={{ md: 12 }} justifyContent="flex-end">
          <Button
            component={Link}
            to="/home/edit/"
            variant="contained"
          >
            Modifier
          </Button>
        </Grid2>
        <Grid2 size={{ md: 12 }}>
          {/* welcome */}
          <CardTitle title="Bienvenue">
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 5, md: 10 }}>
              <Stack width={{ md: '50%' }}>
                <Typography
                  dangerouslySetInnerHTML={{__html: replaceWithBr()}}
                />
              </Stack>
              <Stack
                width={{ md: '50%' }}
                justifyContent="center"
                sx={{
                  border: "1px solid",
                  borderColor: "primary.main",
                  borderRadius: 3,
                  padding: 3
                }}
                spacing={2}
              >
                <Typography>
                Le catalogue recense et présente les seuls produits et services commercialisables et accessibles selon votre segment commercial.
                </Typography>
                <Typography fontWeight="bold" color="secondary">
                  Version actuelle en ligne: {dayjs(data?.last_updated_at).format('LL HH:mm')}
                </Typography>
              </Stack>
            </Stack>
          </CardTitle>
        </Grid2>

        {/* Feature */}
        <Grid2 size={{ xs: 12, md: 6 }}>
          <CardTitle 
            title="A la Une"
            align='center' 
            sx={{ background: `linear-gradient(90.16deg, #FA9454 3.05%, #EB6411 80.35%)`}}
          >
            <Stack spacing={4}>
              <Typography align="center" fontWeight="bold">Découvrez ici les dernières actualités Produits et Services</Typography>
              <SliderCustom featured={data?.featured} />
            </Stack>

          </CardTitle>
        </Grid2>

        {/* New */}
        <Grid2 size={{ xs: 12, md: 6 }}>
          <CardTitle 
            title="Nouveautés"
            align='center'
            sx={{ background: `linear-gradient(90.16deg, #61BBD7 3.05%, #10A2CE 80.35%)`}}
          >
            <Stack>
              <Typography align="center" fontWeight="bold" mb={5}>Découvrez ici les nouveautés Produits et Services</Typography>

              {data?.news?.map((el) => (
                <Stack key={el?.id} direction="row" justifyContent="space-between" spacing={2}>
                  <ListItem style={{ display: 'list-item', fontFamily: 'BNPPSans', textAlign: "left" }}>{el?.title}</ListItem>
                  <IconButtonStyled href={el?.link} target="_blank" size='small'>
                    <ArrowForwardIosRoundedIcon fontSize='small'/>
                  </IconButtonStyled>
                </Stack>
              ))}
            </Stack>
          </CardTitle>
        </Grid2>
      </Grid2>
    </CatalogueLayout>
  )
}

export default Page;