import React from 'react'
import { FastField } from 'formik'
import { FormLabel, Stack, Typography } from '@mui/material'
import AuthLayout from '../layouts/AuthLayout'
import { FormikBootstrapInput, FormikBootstrapInputPassword, FormikSubmit } from '@agencelafusee/mui-admin-modules';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import Axios from '../api/axios';
import * as Yup from 'yup';
import Create from '../components/shared/Create';
import SimpleForm from '../components/shared/SimpleForm';
import { useUser } from '../components/routes/hooks';

const Page = () => {
  const user = useUser();

  const handleSubmit = (mutate, values) => {
    mutate(values);
  };

  const handleSuccess = (values) => {
    user.update(values);
  }

  return (
    <AuthLayout
      title="Connexion"
      description="Connectez-vous pour accéder au catalogue Arval"
      links={[
        {
          to: '/recovery/request-token',
          label: 'Mot de passe oublié',
        },
        {
          to: '/register',
          label: 'Créer un compte',
        }
      ]}
    >
      <Create
        initialValues={{
          'email': '',
          'password': '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Email incorrect').required('Requis'),
          password: Yup.string('Mot de passe incorrect').required('Requis'),
        })}
        onSuccess={handleSuccess}
        onSubmit={handleSubmit}
        query={(data) => Axios.post(
          '/auth/login', 
          data,
        )}
      >
        <SimpleForm withAlert>
          <Stack spacing={3} direction="column" textAlign="center" alignItems="center" justifyContent="center">
            <Stack sx={{ '& .MuiFormControl-root .MuiFormLabel-root': { display: "none" }}}>
              <FormLabel sx={{ mb: 0 }} required>Identifiant</FormLabel>
              <FastField 
                component={FormikBootstrapInput}
                placeholder="____________ @arval.fr"
                name="email"
                variant="outlined"
              />
            </Stack>
            <Stack sx={{ '& .MuiFormControl-root .MuiFormLabel-root': { display: "none"}}}>
              <FormLabel sx={{ mb: 0 }} required>Mot de passe</FormLabel>
              <FastField 
                component={FormikBootstrapInputPassword}
                name="password"
                variant="outlined"
                showIcon={<VisibilityOffIcon />}
                hideIcon={<VisibilityIcon />}
                autoComplete="off"
              />
            </Stack>
            <FormikSubmit>Connexion</FormikSubmit>
            <Typography>
              <Typography component="span" color="error">*</Typography>
              {" "}Champ obligatoire
            </Typography>
          </Stack>
        </SimpleForm>
      </Create>
    </AuthLayout>
  )
}

export default Page;