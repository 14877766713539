import React, { useState } from "react";
import { Grid2, Stack } from "@mui/material";
import { TopMenu } from "../components/menus";
import Axios from "../api/axios";
import { useQuery } from "react-query";
import CatalogSearchInput from "../components/catalog/SearchInput";
import CatalogSearchMenu from "../components/catalog/SearchMenu";
import Head from "../components/shared/Head";

type CatalogLayoutProps = {
  children: any;
  action?: any;
  title: any;
  description?: any;
};

const CatalogueLayout = ({
  title,
  description,
  children,
  action,
}: CatalogLayoutProps) => {
  const [open, setOpen] = useState(false);

  const { isLoading, error, data } = useQuery("repoData", () =>
    Axios.post("/catalogue").then((res) => res.data.data[0])
  );

  return (
    <>
      <Head title={title} description={description} language="fr" />
      <TopMenu data={data} isLoading={isLoading} error={error} />
      <Grid2 container>
        <Grid2 size={{ xs: 12 }} bgcolor="#FCFCFC">
          <Stack sx={{ position: "relative" }}>
            <Stack
              m={2}
              mb={4}
              direction={{ xs: "column", md: "row" }}
              justifyContent="space-between"
              alignItems="center"
              zIndex={200}
              spacing={{ xs: 2, md: 0 }}
            >
              <CatalogSearchInput
                sx={{ 
                  width: { xs: "100%", md: "33%" }, 
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(!open)
                }}
              />
              <CatalogSearchMenu open={open} onClose={() => setOpen(false)} />
              {action}
            </Stack>
            <Stack m={2}>{children}</Stack>
          </Stack>
        </Grid2>
      </Grid2>
    </>
  );
};

export default CatalogueLayout;
