import React from 'react';
import DefenseIcon from '../../../icons/DefenseIcon';
import ForbiddenIcon from '../../../icons/ForbiddenIcon';
import OptionIcon from '../../../icons/OptionIcon';
import StandardIcon from '../../../icons/StandardIcon';

const SegmentStatusIcon = ({ status, ...otherProps }: any) => {
  switch (status) {
    case 'O':
      return <OptionIcon color="info" {...otherProps} />;
    case 'S':
      return <StandardIcon color="success" {...otherProps} />;
    case 'I':
      return <ForbiddenIcon sx={{ color: '#9c27b0'}} {...otherProps} />;
    case 'DE':
      return <DefenseIcon color="warning" {...otherProps} />;
    default:
      return <OptionIcon color="info" {...otherProps} />;
  }
}

export default SegmentStatusIcon;