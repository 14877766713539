import React from "react";
import Grid from "@mui/material/Grid2";
import FeatureAccordion from "./FeatureAccordion";

interface FeatureAccordionGridProps {
  expandedAll: boolean;
  features: any;
}

const FeatureAccordionGrid = ({
  expandedAll,
  features,
}: FeatureAccordionGridProps) => {
  if (features) {
    return (
      <Grid container spacing={2}>
        {features.map((feature: any) => (
          <Grid key={`Accordion-${feature.id}`} size={{ xs: 12, md: 6, lg: 4 }}>
            <FeatureAccordion
              expandedAll={expandedAll}
              title={feature.name}
              sync={feature.sync}
              subjects={feature.subjects}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  return null;
};

export default FeatureAccordionGrid;
