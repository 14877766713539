import { Stack, Typography, styled } from '@mui/material';
import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { 
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon
} from '@mui/icons-material';

const SliderStyle = styled(Slider)(({ theme }) => ({
  width: '100%',
  maxWidth: '100vw',
  '& .slick-slide > div': {
    padding: 15
  },
  '& .slick-prev:hover, .slick-next:hover': {
    color: '#FA9454'
  },
  '& .slick-dots': {
    bottom: '-50px'
  },
  [theme.breakpoints.down('md')]: {
    '& .slick-dots': {
      bottom: 0
    },
  },
  '& .slick-dots li button:before': {
    border: '1px solid #FA9454',
    opacity: "initial",
    width: 5,
    height: 5,
    fontSize: 'unset',
    borderRadius: 5,
    color: 'initial'
  },
  '& .slick-dots li.slick-active button:before': {
    backgroundColor: '#FA9454',
    opacity: "initial"
  },
}))

const PrevArrow = ({currentSlide, slideCount, ...props}) => (
  <div {...props}>
    <ArrowBackIosIcon sx={{ color: "#FA9454" }} fontSize='large' />
  </div>
);

const NextArrow = ({currentSlide, slideCount, ...props}) => (
  <div {...props}>
    <ArrowForwardIosIcon sx={{ color: "#FA9454" }} fontSize='large' />
  </div>
);

const SliderCustom = ({ featured }) => {
  var settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true
        }
      },
    ]
  };

  return (
    <Stack
      sx={{
        border: "1px solid",
        borderColor: "#FA9454",
        borderRadius: 3,
        padding: { md: 8 },
      }}
    >
      <SliderStyle {...settings}>
        {featured?.map((el) => (
          <Stack key={el.id} direction={{ xs: 'column', md: 'row' }}>
            <Typography 
              component="h4" 
              variant="h4" 
              textAlign="center"
              mb={3}
            >
              {el.title}
            </Typography>
            <Typography 
              variant="body1"
            >
              {el.content}
            </Typography>
          </Stack>
        ))}
      </SliderStyle>
    </Stack>
  )
}

export default SliderCustom