import React from 'react';
import ModalBase from '../shared/ModalBase';
import { FormLabel, Stack, Typography, FormControlLabel, Radio } from '@mui/material'
import { FormikRadioGroup, FormikSubmit } from '@agencelafusee/mui-admin-modules';
import axios from '../../api/axios';
import Query from '../shared/Query';
import Create from '../shared/Create';
import SimpleForm from '../shared/SimpleForm';
import { useUser } from '../routes/hooks';

const UserEditModal = ({control, ...props}) => {
  const user = useUser();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSubmit = (mutate, values) => {
    mutate({...values, segment: Number(values.segment) });
  };

  const handleSuccess = (_, values) => {
    user.update({ ...user.data, ...values });
    setOpen(false);
  }

  return (
    <>
      {React.cloneElement(control, { onClick: handleOpen })}
      <ModalBase 
        open={open} 
        onClose={handleClose} 
        title="Profil" 
        sx={{ 
          width: { xs: "95%", lg: 900 }, 
          height: { md: 'auto' } 
        }} 
        {...props}
      >
        <Create
          initialValues={{
            'segment': user.data?.segment || '',
            'position': user.data?.position || '',
          }}
          query={(data) => axios.put('/users/me', data)}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
        >
          <SimpleForm withAlert>
            <Stack spacing={5} direction="column" textAlign="center" alignItems="center" justifyContent="center">
              <Stack>
                <FormLabel>Choisissez votre segment commercial</FormLabel>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Query
                    queryKey='segments'
                    queryFn={(() => axios.get('/data/segments').then(res => res.data?.data))}
                  >
                    {({ data }) => (
                      <FormikRadioGroup
                        required
                        name="segment"
                        key={`CheckboxGroup`}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row'
                        }}
                      >
                        {data?.map(segment => (
                          <FormControlLabel
                            control={<Radio value={segment.id} />}
                            label={<Typography>{segment.name}</Typography>}
                          />
                        ))}
                      </FormikRadioGroup>
                    )}
                  </Query>
                </Stack>
              </Stack>
              <Stack>
                <FormLabel>Choisissez votre fonction</FormLabel>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <FormikRadioGroup
                  name="position"
                  key={`CheckboxGroup`}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                  >
                    <FormControlLabel 
                      control={<Radio value="ICE" />}
                      label={<Typography>ICE</Typography>}
                    />
                    <FormControlLabel 
                      control={<Radio value="rdc" />}
                      label={<Typography>RDC</Typography>}
                    />
                    <FormControlLabel 
                      control={<Radio value="atc" />}
                      label={<Typography>ATC</Typography>}
                    />
                    <FormControlLabel 
                      control={<Radio value="other" />}
                      label={<Typography>Autres</Typography>}
                    />
                  </FormikRadioGroup>
                </Stack>
              </Stack>
              <FormikSubmit>Éditer mon profil</FormikSubmit>
            </Stack>
          </SimpleForm>
        </Create>
      </ModalBase>
    </>
  )
}

export default UserEditModal;