import React from 'react';
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useSearchParams } from 'react-router-dom/dist';
import { segments } from './segments';

const SegmentFilterSelect = (props: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const popupState = usePopupState({ variant: 'popover', popupId: 'segmentMenu' })

  React.useEffect(() => {
    if (!searchParams.get('segment')) {
      const segment = localStorage.getItem('segment');

      if (segment) {
        setSearchParams({
          segment: JSON.parse(segment).key,
        });
      }
    }
  }, []);

  const handleClick = (segment: any) => () => {
    setSearchParams({
      segment: segment.key,
    });
    localStorage.setItem('segment', JSON.stringify(segment));
    popupState.close();
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        endIcon={popupState.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        {...bindTrigger(popupState)}
        {...props}
      >
        {segments.find(segment => segment.key === JSON.parse(localStorage.getItem('segment'))?.key)?.label || 'Sélectionnez un segment'}
      </Button>
      <Menu
        sx={{ 
          '& .MuiPaper-root': {
            width: { xs: '100%', md: '250px'}
          }
        }}
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: 45, horizontal: 'left' }}
        transformOrigin={{ vertical: 0, horizontal: 'left' }}
      >
        {segments.map(segment => (
          <MenuItem key={segment.key} onClick={handleClick(segment)}>{segment.label}</MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default SegmentFilterSelect;