import { Box, Typography, Card, Stack, styled } from '@mui/material'
import React from 'react'
import { CatalogueLayout } from '../layouts'

const TypographyStyled = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent"
}));

const LoadingPage = () => {
  return (
    <CatalogueLayout
      title="Chargement"
      errorPage={false}
    >
      <Card 
        sx={{ 
          height: 'calc(100vh - 120px)', 
          display: "flex", 
          flexDirection: "column", 
          justifyContent: "center", 
          alignItems: "center" 
        }}
      >
        <Stack alignItems="center" textAlign="center" spacing={2}>
          <TypographyStyled
            component="h2" 
            variant="h3"
            fontSize={46}
          >
            Chargement
          </TypographyStyled>
        </Stack>
      </Card>
    </CatalogueLayout>
  )
}

export default LoadingPage