import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { palette } from '../../config/theme.config';
import LoginHistoryChartFilter from './LoginHistoryChartFilter';
import { segments } from '../catalog/segment/segments';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
    },
  },
};

export type Segment = 'GC_IBO' | 'GF' | 'MID_MARKET' | 'PLLD';
export type UserType = 'ICE' | 'RDC' | 'ATC' | 'STL' | 'autres';

type LoginHistoryData = {
  date: string;
  user: number;
  segment: Segment;
  user_type: UserType;
};

interface LoginHistoryChartProps {
  data: LoginHistoryData[],
  filters: {
    date: Date | null;
    segments: Segment[];
  }
}

const labels = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

const filterByUserType = (data: LoginHistoryData[], userTypes: UserType[]) => {
  return data.filter(item => userTypes.includes(item.user_type));
}

/**
 * Sorts the data by month
 * Each table corresponds to a month
 * 
 * Example:
 * Receved: [{}, {}, {}, {}, {}, {}];
 * 
 * expected: [
 *  [{}, {}] // January
 *  [{}, {}] // February
 *  ...
 *  [{}, {}] // December
 * ]
 */
const sortDataByMonth = (data: LoginHistoryData[], year: number) => {
  return data.reduce(
    (accumulator, currentValue) => {
      const date = currentValue.date ? new Date(currentValue.date) : null;

      if (!date) {
        return accumulator;
      }

      if (date.getFullYear() === year) {
        accumulator[date.getMonth()]?.push(currentValue);
      }

      return accumulator;
    },
    [[], [], [], [], [], [], [], [], [], [], [], []]
  );
}

const LoginHistoryChart = ({ data = [] }: LoginHistoryChartProps) => {
  const [filters, setFilters] = React.useState<{segments: Segment[], types: UserType[], date: Date }>({ segments: ['GC_IBO'], types: ['ICE'], date: new Date() });
  
  const chartDataMemorized = React.useMemo(() => {
    const dataFilteredByUserType = filterByUserType(data, filters.types);
    const dataSortedByMonth = sortDataByMonth(dataFilteredByUserType, filters.date?.getFullYear());

    const chartData = {
      labels,
      datasets: [],
    };

    const segmentColor = {
      GC_IBO: palette.warning.main,
      GF: palette.success.main,
      MID_MARKET: palette.error.main,
      PLLD: '#24B3C7',
    }

    filters.segments.forEach(segment => {
      chartData.datasets.push(
        {
          label: segments.find(item => item.key === segment)?.label,
          data: Array.from(Array(12)).map((_, key) => dataSortedByMonth[key].filter(data => data.segment === segment)?.length || 0),
          backgroundColor: segmentColor[segment],
          borderColor: segmentColor[segment],
          fill: 1,
          tension: 0.5
        },
      )
    });

    return chartData;
  }, [data, filters]);

  const handleChangeFilters = (newFilters) => {
    setFilters(newFilters);
  }

  return (
    <>
      <LoginHistoryChartFilter filters={filters} onChange={handleChangeFilters} />
      <Line options={options} data={chartDataMemorized}/>
    </>
  )
}

export default LoginHistoryChart;