import React from "react";
import {
  Stack,
  Link as MuiLink,
  List,
  Button,
  ListItem,
  Typography,
  Box,
  MenuItem,
  styled,
} from "@mui/material";
import CascadingMenu from "./cascadingMenu/CascadingMenu";
import CascadingMenuItem from "./cascadingMenu/CascadingMenuItem";
import PopupState from "material-ui-popup-state";
import ButtonPopover from "./buttonPopover/ButtonPopover";
import { Link } from "react-router-dom";
import { ResponsiveDrawer } from "@agencelafusee/mui-admin-modules";
import { useQuery } from "react-query";
import axios from "../../api/axios";
import UserEditModal from "../user/UserEditModal";
import ArvalLogo from "../../assets/img/arvalLogo.png";
import {
  DashboardIcon,
  LogoutIcon,
  ProfilIcon,
  TutorialIcon,
} from "../../icons";
import TutorialModal from "../TutorialModal";
import { useUser } from "../routes/hooks";

const ButtonStyled = styled(Button)({
  "& .MuiSvgIcon-root": {
    fontSize: "26px!important",
  },
});

const BoxStyled = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "8px 16px",
});

const LeftMenu = (props) => {
  const { data } = useQuery("catalogs", () =>
    axios.get("/catalogue").then((res) => res.data?.data)
  );
  const user = useUser();

  const handleLogOut = () => {
    user.update(null);
  };

  return (
    <ResponsiveDrawer window={() => window} width={240} {...props}>
      <Box p={2} mb={5}>
        <Link to="/home">
          <img src={ArvalLogo} width="151px" alt="arval-logo" />
        </Link>
      </Box>
      <List>
        {data?.[0]?.products?.map((item, key) => (
          <BoxStyled key={`SegmentInfoSelect-ListItem-${key}`}>
            <PopupState
              key={`Menu-${item.id}`}
              popupId="demoMenu"
              variant="popover"
            >
              {(popupState) => (
                <>
                  <ButtonPopover popupState={popupState} component={MenuItem}>
                    <Typography variant="h5" component="span" fontSize={16}>
                      {item?.name}
                    </Typography>
                  </ButtonPopover>
                  <CascadingMenu
                    popupState={popupState}
                    anchorOrigin={{ vertical: 0, horizontal: "right" }}
                    transformOrigin={{ vertical: 0, horizontal: "left" }}
                  >
                    {item.services.map((el, key) => (
                      <CascadingMenuItem
                        key={`LeftMenu-Item-${key}`}
                        component={Link}
                        to={`/catalog/${item.slug}/services/${el.slug}`}
                      >
                        {el.name}
                      </CascadingMenuItem>
                    ))}
                  </CascadingMenu>
                </>
              )}
            </PopupState>
          </BoxStyled>
        ))}
      </List>
      <Box sx={{ flexGrow: 1 }} />
      {/* Menu footer */}
      <Stack>
        <List>
          {user.hasSomeRoles(["ROLE_SUPER_ADMIN"]) && (
            <ListItem>
              <ButtonStyled
                startIcon={<DashboardIcon />}
                component={Link}
                to="/dashboard"
              >
                Dashboard
              </ButtonStyled>
            </ListItem>
          )}
          <ListItem>
            <TutorialModal
              control={
                <ButtonStyled startIcon={<TutorialIcon />}>
                  Tutoriel
                </ButtonStyled>
              }
            />
          </ListItem>
          <ListItem>
            <UserEditModal
              control={
                <ButtonStyled startIcon={<ProfilIcon />}>Profil</ButtonStyled>
              }
            />
          </ListItem>
          <ListItem>
            <ButtonStyled startIcon={<LogoutIcon />} onClick={handleLogOut}>
              Déconnexion
            </ButtonStyled>
          </ListItem>
        </List>
        <List>
          <ListItem>
            <MuiLink color="inherit" href="mailto:bruno.lebihan@arval.fr">
              Contactez-nous
            </MuiLink>
          </ListItem>
          <ListItem>
            {user.hasSomeRoles(["ROLE_SUPER_ADMIN"]) && (
              <MuiLink component={Link} to="/files" color="inherit">
                Chargez un fichier Excel
              </MuiLink>
            )}
          </ListItem>
        </List>
      </Stack>
    </ResponsiveDrawer>
  );
};

export default LeftMenu;
