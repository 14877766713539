import React from 'react';
import { List, ListProps } from '@mui/material';
import FileListItem from './FileListItem';

interface FileListProps extends ListProps {
  items?: any[];
  isLoading?: boolean;
  perPage?: number;
  refetch: any;
}

const FileList = ({ items, isLoading, perPage, refetch, ...otherProps }: FileListProps) => (
  <List sx={{ minHeight: 200 }} {...otherProps}>
    {isLoading ? Array.from(Array(perPage))
      .map((_, index) =>
        <FileListItem key={`item-${index}`} refetch={refetch} />
      ) : items?.map(item =>
      <FileListItem  key={`item-${item.id}`} item={item} refetch={refetch}/>
    )}
  </List>
);

export default FileList;