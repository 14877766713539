import React from "react";
import { TextField, InputAdornment, TextFieldProps } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";

const CatalogSearchInput = (props: TextFieldProps) => (
  <TextField
    variant="standard"
    color="primary"
    slotProps={{
      input: {
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon color="primary" />
          </InputAdornment>
        ),
      },
    }}
    sx={{
      px: 2,
      width: { xs: "auto", md: "100%" },
    }}
    {...props}
  />
);

export default CatalogSearchInput;
