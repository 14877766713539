import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { segments } from '../catalog/segment/segments';

const LoginHistoryChartFilter = ({ filters, onChange }) => {
  const handleChecked = (type) => (event) => {
    if (event.target.checked) {
      filters[type].push(event.target.name);
    } else {
      if (filters[type].length > 1) {
        filters[type] = filters[type].filter(filter => filter !== event.target.name);
      }
    }

    onChange({...filters});
  }

  return (
    <Stack spacing={5}>
    <Stack direction={{ sm: 'column', lg: 'row'}} alignItems={{ lg: 'center'}} spacing={{ sm: 5, lg: 20}}>
        <FormControl component="fieldset" variant="standard">
          <FormLabel sx={{ textAlign: 'left'}} component="legend">Sélectionnez un ou plusieurs segments</FormLabel>
          <FormGroup row>
            {segments.map(segment => (
              <FormControlLabel
                key={segment.key}
                control={
                  <Checkbox checked={filters.segments.includes(segment.key)} onChange={handleChecked('segments')} name={segment.key} />
                }
                label={segment.label}
              />
            ))}
          </FormGroup>
        </FormControl>
        <div>
        <FormLabel sx={{ justifyContent: 'left'}} component="legend">Sélectionnez une période</FormLabel>
        <DatePicker
          views={['year']}
          value={filters.date}
          onChange={(newValue) => {
            onChange({
              ...filters,
              date: newValue ? new Date(newValue) : null,
            })
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </div>
      </Stack>
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormLabel sx={{ textAlign: 'left'}} component="legend">Sélectionnez un ou plusieurs types de population</FormLabel>
          <FormGroup row>
            {['ICE', 'RDC', 'ATC', 'STL', 'autres'].map(type => (
              <FormControlLabel
                key={type}
                control={
                  <Checkbox checked={filters.types.includes(type)} onChange={handleChecked('types')} name={type} />
                }
                label={type.toUpperCase()}
              />
            ))}
          </FormGroup>
        </FormControl>
    </Stack>
  )
}

export default LoginHistoryChartFilter;